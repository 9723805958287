import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import axiosClient from '@src/client/axiosClient'
import { ChevronRightIcon, EnvelopeIcon, HomeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import moment from 'moment'


export default function ApplicationDetail() {
  const { applicantId } = useParams()
  const [applicant, setApplicant] = useState<any>()
  const [application, setApplication] = useState<any>()

  useEffect(() => {
    axiosClient.get(`/api/v1/application/applicants/${applicantId}`)
      .then(res => {
        const fetchedApplicant = res.data?.data
        setApplicant(fetchedApplicant)
      })
      .catch(console.error)

    axiosClient.get('/api/v1/application', {
      params: {
        applicantId,
      },
    })
      .then(res => {
        const fetchedApplication = res.data?.data
        setApplication(fetchedApplication)
      })
      .catch(console.error)
  }, [])

  const pages = [
    { name: '지원자', href: '#', current: false },
    { name: `id: ${applicant?.id} - (${applicant?.nickname})`, href: '#', current: true },
  ]

  return (
    <div>
      <nav className="flex mb-8" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <Link to="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div>
        <article>
          <div>
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                <h1 className="truncate text-2xl font-bold text-gray-900">{applicant?.email} ({applicant?.nickname})</h1>
              </div>
            </div>
          </div>

          <h3 className="mt-8 text-center text-[24px]">지원자 정보</h3>
          <div className="mt-4 border border-b w-full" />

          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {applicant && Object.keys(applicant).map((field) => {
                let fieldValue = applicant[field]
                switch(field) {
                  case 'isOg': {
                    fieldValue = applicant[field] ? 'true' : 'false'
                    break
                  }
                  case 'web3Experiences': {
                    fieldValue = applicant[field].length === 0 ? '없음' : JSON.stringify(applicant[field])
                    break
                  }
                  case 'profile': {
                    fieldValue = JSON.stringify(applicant[field])
                    break
                  }
                  case 'web3Industries': {
                    fieldValue = applicant[field].length === 0 ? '없음' : applicant[field].join(', ')
                    break
                  }
                  case 'createdAt':
                  case 'updatedAt':
                  case 'deletedAt': {
                    if (fieldValue) {
                      fieldValue = moment(moment.utc(fieldValue, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate()).local().format('YYYY-MM-DD HH:mm:ss')
                    }
                    break
                  }
                }

                return (
                  <div key={field} className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">{field}</dt>
                    <dd className="mt-1 text-sm text-gray-900">{fieldValue}</dd>
                  </div>
                )
              })}
            </dl>
          </div>

          <h3 className="mt-8 text-center text-[24px]">지원서 내용</h3>
          <div className="mt-4 border border-b w-full" />
          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {application && Object.keys(application).map((field) => {
                let fieldValue = application[field]
                switch(field) {
                  case 'createdAt':
                  case 'updatedAt':
                  case 'deletedAt': {
                    if (fieldValue) {
                      fieldValue = moment(moment.utc(fieldValue, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate()).local().format('YYYY-MM-DD HH:mm:ss')
                    }
                    break
                  }
                }

                return (
                  <div key={field} className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">{field}</dt>
                    <dd className="mt-1 text-sm text-gray-900">{fieldValue}</dd>
                  </div>
                )
              })}
            </dl>
          </div>

        </article>
      </div>
    </div>
  )
}
