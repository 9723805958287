import authStore from '@src/stores/authStore'
import globalUiStore from '@src/stores/globalUiStore'
import { configurePersistable } from 'mobx-persist-store';
import localForage from 'localforage'

configurePersistable(
  {
    storage: window.localStorage,
    expireIn: 24 * 60 * 60 * 1000,
    removeOnExpiration: true,
    stringify: false,
    debugMode: true,
  },
  { delay: 200, fireImmediately: false },
)

class RootStore {
  authStore = authStore
  globalUiStore = globalUiStore
}

const rootStore = new RootStore()

export default rootStore
