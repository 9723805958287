import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import { makePersistable } from 'mobx-persist-store';
import axiosClient from '@src/client/axiosClient'

interface AdminUser {
  id: number
  email: string
  name: string
}

export class AuthStore {
  @observable
  adminUser: AdminUser | null = null

  @computed
  get isLoggedIn() {
    return this.adminUser !== null
  }

  constructor() {
    makeObservable(this)
    makePersistable(this, { name: 'authStore', properties: ['adminUser'], storage: window.localStorage })
  }

  @action
  async login(email, password) {
    const res = await axiosClient.post('/login', {
      email,
      password,
    })
    runInAction(() => {
      this.adminUser = res.data
    })
  }

  @action
  async logout() {
    await axiosClient.post('/logout')
    runInAction(() => {
      this.adminUser = null
    })
  }
}

const authStore = new AuthStore()

export default authStore
