import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import router from './router'
import { Provider } from 'mobx-react'
import rootStore from '@src/stores/rootStore'
import { RouterProvider } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  <React.StrictMode>
    <Provider {...rootStore}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
