import classNames from 'classnames'
import { HomeIcon, EnvelopeIcon, FaceSmileIcon } from '@heroicons/react/24/outline'
import { inject, observer } from 'mobx-react'
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom'


const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: '지원자', href: '/applicants', icon: FaceSmileIcon },
  { name: 'OG 지원자', href: '/og-applicants', icon: FaceSmileIcon },
  { name: '초대코드', href: '/invitation-codes', icon: EnvelopeIcon },
]

function MainTemplate(props) {
  const { authStore } = props
  const { adminUser } = authStore
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogout = async () => {
    await authStore.logout()
    navigate('/login')
  }

  return (
    <>
      <div className="fixed inset-y-0 flex w-64 flex-col">
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="h-8 w-auto"
                src="/logo192.png"
              />
              <span className="ml-4 font-bold text-xl">
                1eg selective
              </span>
            </div>
            <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
              {navigation.map((item) => {
                const firstPathname = location.pathname.split('/')[1]
                const hrefWithoutSlash = item.href.split('/')[1]
                const isMatched = firstPathname === hrefWithoutSlash

                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      isMatched ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        isMatched ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                    />
                    {item.name}
                  </Link>
                )
              })}
            </nav>
          </div>
          {authStore.isLoggedIn && (
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <div className="w-full flex justify-between items-center">
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{adminUser?.name}</p>
                  <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{adminUser?.email}</p>
                </div>
                <button
                  type="button"
                  className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleLogout}
                >
                  logout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <main className="relative ml-64 min-h-screen p-8">
        <Outlet />
      </main>
    </>
  )
}

export default inject('authStore')(observer(MainTemplate))
