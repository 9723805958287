import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const AdminOnly = (props) => {
  const { authStore, children } = props
  const navigate = useNavigate()

  const isLoggedIn = authStore.isLoggedIn

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login')
    }
  }, [])

  return children
}

export default inject('authStore')(observer(AdminOnly))
