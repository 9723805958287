import { inject, observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

function Login(props) {
  const { authStore } = props
  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: 'admin@egomint.io',
      password: '',
    }
  })

  const handleLogin = async data => {
    await authStore.login(data.email, data.password)

    navigate('/')
  }

  return (
    <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Log in
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(handleLogin)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  {...register('email')}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  {...register('password')}
                />
              </div>
            </div>

            {/*<div className="flex items-center justify-between">*/}
            {/*  <div className="flex items-center">*/}
            {/*    <input*/}
            {/*      id="remember-me"*/}
            {/*      name="remember-me"*/}
            {/*      type="checkbox"*/}
            {/*      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"*/}
            {/*    />*/}
            {/*    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">*/}
            {/*      Remember me*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {errors.password && <span role="alert">{errors.password.message as string}</span>}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default inject('authStore')(observer(Login))
