import { useEffect, useState } from 'react'
import axiosClient from '@src/client/axiosClient'
import { useNavigate } from 'react-router-dom'

export default function OgApplicants() {
  const navigate = useNavigate()
  const [applicants, setApplicants] = useState<Applicant[]>([])

  useEffect(() => {
    axiosClient.get('/api/v1/application/applicants', {
      params: {
        is_og: true,
      },
    })
      .then(res => {
        const fetchedApplicants = res.data?.data
        setApplicants(fetchedApplicants)
      })
      .catch(console.error)
  }, [])

  const handleApplicationDetail = (applicant: Applicant) => {
    navigate({
      pathname: `/applications/applicant/${applicant.id}`,
    })
  }

  return (
    <div className="relative">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            지원자 리스트
          </h1>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                    Id
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                    Nickname
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Twitter
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Job
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Detail
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {applicants.map((applicant) => (
                  <tr key={applicant.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {applicant.id}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {applicant.nickname}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{applicant.email}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{applicant.twitter}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{applicant.job}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={() => handleApplicationDetail(applicant)}
                      >
                        지원서 보기
                      </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface Applicant {
  id: number
  email: string
  walletAddress: string
  nickname: string
  twitter: string
  instagram: string
  blog: string
  job: string
  web3Experiences: string[]
  web3Perspective: string
  web3Ability: string
  web3Industries: string[]
  privacyAgree: boolean
  privacyProvideAgree: boolean
  marketingAgree: boolean
}

function booleanToOX(b) {
  return b ? 'O' : 'X'
}
