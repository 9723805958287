import Login from '@src/Login'
import MainTemplate from '@src/MainTemplate'
import AdminOnly from '@src/AdminOnly'
import { createBrowserRouter } from 'react-router-dom'
import ApplicationDetail from '@src/pages/applications/ApplicationDetail'
import Invitations from '@src/pages/invitations/Invitations'
import OgApplicants from '@src/pages/ogApplicants/OgApplicants'
import Applicants from '@src/pages/applicants/Applicants'

const router = createBrowserRouter([{
  path: '',
  children: [{
    path: '/',
    element: <AdminOnly><MainTemplate /></AdminOnly>,
    children: [{
      path: 'applicants',
      element: <Applicants />,
    }, {
      path: 'og-applicants',
      element: <OgApplicants />,
    }, {
      path: 'applications/applicant/:applicantId',
      element: <ApplicationDetail />,
    }, {
      path: 'invitation-codes',
      element: <Invitations />,
    }],
  }, {
    path: '/login',
    element: <Login />,
  }],
}])

export default router
