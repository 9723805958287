import { useEffect, useState } from 'react'
import axiosClient from '@src/client/axiosClient'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function Invitations() {
  const [invitationCodes, setInvitationCodes] = useState<InvitationCode[]>([])

  useEffect(() => {
    axiosClient.get('/api/v1/invitation-code?page=1&limit=1000')
      .then(res => {
        const data = res.data?.data
        setInvitationCodes(data)
      })
      .catch(console.error)
  }, [])

  return (
    <div className="relative">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            초대코드 리스트
          </h1>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                    Id
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                    Code
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Used by
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Created
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Updated
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {invitationCodes.map((invitationCode) => (
                  <tr key={invitationCode.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {invitationCode.id}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {invitationCode.code}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invitationCode.status}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {invitationCode.applicantId ? (
                        <Link to={`/applications/applicant/${invitationCode.applicantId}`}>
                          {invitationCode.applicantNickname}
                        </Link>
                      ) : ''}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(invitationCode.createdAt).format('YYYY-MM-DD')}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(invitationCode.updatedAt).format('YYYY-MM-DD')}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface InvitationCode {
  id: number
  code: string
  status: 'used' | 'unused'
  applicantId?: number
  applicantNickname?: string
  ownerId: number
  ownerType: 'AdminUser'
  createdAt: Date
  updatedAt: Date
  deletedAt: Date
}
