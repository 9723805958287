import { Fragment, useEffect, useState } from 'react'
import axiosClient from '@src/client/axiosClient'
import { useNavigate } from 'react-router-dom'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

interface ListBoxIdLabel {
  id: string
  label: string
}

const INVISIBLE_CHAR = `‎`

const applicantStatuses = [{
  id: 'APPLICATION_PASSED',
  label: '지원서 합격',
}, {
  id: 'APPLICATION_FAILED',
  label: '지원서 불합격',
}, {
  id: 'COFFEE_CHAT_FAILED',
  label: '커피챗 불합격',
}, {
  id: 'FINAL_PASSED',
  label: '최종 합격',
}, {
  id: '1EGDAO_MEMBER',
  label: '1egdao 멤버',
}]

export default function Applicants() {
  const navigate = useNavigate()
  const [applicants, setApplicants] = useState<(Applicant & { code: string })[]>([])

  useEffect(() => {
    fetchApplicants().then()
  }, [])

  const fetchApplicants = async () => {
    const { data: { data: applicants } } = await axiosClient.get('/api/v1/application/applicants', {
      params: {
        is_og: false,
      },
    })
    setApplicants(applicants)
  }

  const handleApplicationDetail = (applicant: Applicant) => {
    navigate({
      pathname: `/applications/applicant/${applicant.id}`,
    })
  }

  const handleUpdateStatus = async (id: number, newStatus: string) => {
    try {
      await axiosClient.put(`/api/v1/applicant/${id}`, { status: newStatus })
    } catch (e) {
      console.error(e)
      alert('지원자 상태를 변경하는데 실패했습니다')
    } finally {
      await fetchApplicants()
    }
  }

  return (
    <div className="relative">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            지원자 리스트
          </h1>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full mb-20 divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                    Id
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                    Nickname
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Twitter
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Job
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Invitation code
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Detail
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {applicants.map((applicant) => (
                  <tr key={applicant.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {applicant.id}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {applicant.nickname}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{applicant.email}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{applicant.twitter}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{applicant.job}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{applicant.code}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                      <Listbox value={applicant.status} onChange={async (newStatus) => {
                        if (applicant.status !== newStatus) {
                          await handleUpdateStatus(applicant.id, newStatus)
                        }
                      }}>
                        {({ open }) => (
                          <>
                            <div className="relative mt-2">
                              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">{applicantStatuses.find(s => s.id === applicant.status)?.label || INVISIBLE_CHAR}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {applicantStatuses.map((idLabel) => (
                                    <Listbox.Option
                                      key={idLabel.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                          'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )
                                      }
                                      value={idLabel.id}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                            {idLabel.label}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? 'text-white' : 'text-indigo-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 text-center">
                      <button
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={() => handleApplicationDetail(applicant)}
                      >
                        지원서 보기
                      </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface Applicant {
  id: number
  email: string
  walletAddress: string
  nickname: string
  twitter: string
  instagram: string
  blog: string
  job: string
  web3Experiences: string[]
  web3Perspective: string
  web3Ability: string
  web3Industries: string[]
  privacyAgree: boolean
  privacyProvideAgree: boolean
  marketingAgree: boolean
  status: string
}
