import { observable, action, makeObservable } from 'mobx'

export class GlobalUiStore {
  @observable
  isStoreLoaded: boolean = false

  constructor() {
    makeObservable(this)
  }

  @action
  async setIsStoreLoaded(isLoaded: boolean) {
    this.isStoreLoaded = isLoaded
  }
}

const globalUiStore = new GlobalUiStore()

export default globalUiStore
