import _ from 'lodash'

type AppEnv = 'local' | 'development' | 'production'

export const appEnv: AppEnv = process.env.REACT_APP_APP_ENV ? process.env.REACT_APP_APP_ENV as AppEnv : 'local'

interface Config {
  app: {
    api: {
      httpEndpoint: string
    }
  }
}

const defaultConfig = {
}

const configs = {
  local: {
    app: {
      api: {
        httpEndpoint: 'http://localhost:8080',
      },
    },
  },
  development: {
    app: {
      api: {
        httpEndpoint: 'https://api-dev.1egdao.io',
      },
    },
  },
  production: {
    app: {
      api: {
        httpEndpoint: 'https://api.1egdao.io',
      },
    },
  },
}

const config: Config = _.merge(defaultConfig, configs[appEnv])

export default config
